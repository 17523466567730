'use client'
import classnames from 'classnames'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import FullLogo from './_images/full.svg'
import * as M from '~/utils/Map'
import styles from './styles.module.css'
import Image from 'next/image'
import isChromatic from 'chromatic/isChromatic'
import {Analytics} from '~/utils/Analytics'
import {MWDSUnstyledButton} from '~/mercuryWebCompat/design-system/MWDSUnstyledButton'
import buttonStyles from '~/mercuryWebCompat/design-system/MWDSButtonLink/styles.module.css'
import {
  type PuzzleMap,
  convertPuzzleMapToRecord,
  depth,
  emptyPieceIndex,
  numPuzzlePieces,
} from '~/app/_components/NotFound/SliderPuzzle/_utils/helpers'
import {useQuery} from '@tanstack/react-query'
import MWDSLoadingDots from '~/mercuryWebCompat/design-system/MWDSLoadingIndicator/MWDSLoadingDots'
import {notifyBugsnag} from '~/utils/Bugsnag/notify'

const gridGap = '7px'

export function generateInitialBoard() {
  const puzzleMap: PuzzleMap = new Map()
  for (let i = 0; i < numPuzzlePieces; i++) {
    puzzleMap.set(i, {
      src: `/icons/NotFound/piece-${i}.svg`,
      currentIndex: i,
    })
  }
  puzzleMap.set(emptyPieceIndex, {src: 'empty', currentIndex: numPuzzlePieces})
  return puzzleMap
}

function generateTranslationMatrix() {
  const matrix: string[] = []
  for (let rowIndex = 0; rowIndex < depth; rowIndex++) {
    for (let colIndex = 0; colIndex < depth; colIndex++) {
      matrix.push(
        `translate(calc(100% * ${colIndex} + ${gridGap} * ${colIndex}), calc(100% * ${rowIndex} + ${gridGap} * ${rowIndex}))`
      )
    }
  }
  return matrix
}

const initialBoard = generateInitialBoard()
const translationMatrix = generateTranslationMatrix()

function generateStorybookBoard(puzzleMap: PuzzleMap) {
  const copy = M.clone(puzzleMap)
  const emptyPiece = copy.get(emptyPieceIndex)
  const secondToLastPiece = copy.get(numPuzzlePieces - 1)

  if (!emptyPiece || !secondToLastPiece) {
    return puzzleMap
  }

  copy.set(emptyPieceIndex, {
    ...emptyPiece,
    currentIndex: secondToLastPiece.currentIndex,
  })
  copy.set(numPuzzlePieces - 1, {
    ...secondToLastPiece,
    currentIndex: emptyPiece.currentIndex,
  })

  return copy
}

// Fisher-Yates Shuffle
// https://www.tutorialspoint.com/what-is-fisher-yates-shuffle-in-javascript
function shuffleBoard(puzzleMap: PuzzleMap): PuzzleMap {
  const copy = M.clone(puzzleMap)

  for (let i = copy.size - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const piece1 = copy.get(i)
    const piece2 = copy.get(j)

    if (!piece1 || !piece2) {
      continue
    }

    copy.set(i, {...piece1, currentIndex: piece2.currentIndex})
    copy.set(j, {...piece2, currentIndex: piece1.currentIndex})
  }

  const isUnsolvable = !checkSolvability(copy)

  // keep shuffling the board until it is solvable
  if (isUnsolvable) {
    return shuffleBoard(puzzleMap)
  }
  return copy
}

/*
Solving a sliding puzzle is essentially just inverting adjacent tiles until the array is sorted.

We can ignore the empty tile since that is not a tile a user can invert.

In this simple algorithm, we iterate over pairs of elements in the array. If we find a pair where the outer loop is greater than the inner loop, that means that the elements are in reverse order, and thus, need to be inverted.

We could replace this with a merge sort algorithm, but that might be overly complex in this case since we know the array will always be a fixed size.

Sliding puzzles are only solvable if the number of inversions is even.
See https://www.geeksforgeeks.org/check-instance-8-puzzle-solvable/ for more details.
*/
function checkSolvability(puzzleMap: PuzzleMap) {
  let inversions = 0
  const array = M.values(puzzleMap)
  for (let i = 0; i < array.length - 1; i++) {
    for (let j = i + 1; j < array.length; j++) {
      if (
        array[i].currentIndex > array[j].currentIndex &&
        array[j].src !== 'empty' &&
        array[i].src !== 'empty'
      ) {
        inversions++
      }
    }
  }

  const isEven = inversions % 2 === 0
  return isEven
}

const SliderPuzzle = () => {
  const [isComplete, setIsComplete] = useState(false)
  const [autoSolved, setAutoSolved] = useState(false)
  const [numTurns, setNumTurns] = useState(0)
  const {
    refetch: solvePuzzle,
    isLoading,
    error,
    data: solution,
  } = useQuery({
    queryKey: ['puzzle-solver'],
    enabled: false,
    gcTime: 0,
    queryFn: async ({signal}) => {
      // unable to stringify a ES6 Map, so convert it to a record
      const puzzleRecord = convertPuzzleMapToRecord(shuffledBoard.current)
      const response = await fetch('/api/puzzle-solver', {
        method: 'POST',
        body: JSON.stringify(puzzleRecord),
        signal,
      })

      if (!response.ok) {
        notifyBugsnag('Failed to solve puzzle', {
          caughtError: response.statusText,
        })
        throw new Error('Failed to solve puzzle. Please try again later.')
      }

      const solution: string[] = await response.json()

      return solution
    },
  })
  // prevent interacting with the board until the shuffle animation plays
  const [isShuffled, setIsShuffled] = useState(false)

  const puzzleContainerRef = useRef<HTMLDivElement>(null)

  const shuffledBoard = useRef<ReturnType<typeof shuffleBoard>>(
    isChromatic() ? generateStorybookBoard(initialBoard) : shuffleBoard(initialBoard)
  )

  const checkAdjacency = useCallback((index: number) => {
    const maybeAdjacentTile = shuffledBoard.current.get(index)
    if (!maybeAdjacentTile) {
      return false
    }
    const emptyTile = shuffledBoard.current.get(emptyPieceIndex)
    if (!emptyTile) return false
    const emptyTileIndex = emptyTile.currentIndex
    const emptyTileRow = Math.floor(emptyTileIndex / depth)
    const emptyTileCol = emptyTileIndex % depth

    const maybeAdjacentRow = Math.floor(maybeAdjacentTile.currentIndex / depth)
    const maybeAdjacentCol = maybeAdjacentTile.currentIndex % depth

    const rowDiff = Math.abs(emptyTileRow - maybeAdjacentRow)
    const colDiff = Math.abs(emptyTileCol - maybeAdjacentCol)

    const isVerticallyAdjacent = rowDiff === 1 && colDiff === 0
    const isHorizontallyAdjacent = rowDiff === 0 && colDiff === 1

    return isVerticallyAdjacent || isHorizontallyAdjacent
  }, [])

  const swapTiles = useCallback(
    (index: number, buttonEl: HTMLButtonElement) => {
      const isAdjacent = checkAdjacency(index)

      const emptyTile = shuffledBoard.current.get(emptyPieceIndex)
      if (!emptyTile) return
      const emptyTileIndex = emptyTile.currentIndex
      const tile = shuffledBoard.current.get(index)

      if (!isAdjacent || !tile) {
        return
      }
      buttonEl.style.transform = translationMatrix[emptyTileIndex]

      shuffledBoard.current.set(index, {...tile, currentIndex: emptyTileIndex})
      shuffledBoard.current.set(emptyPieceIndex, {
        ...tile,
        currentIndex: tile.currentIndex,
      })
      setNumTurns(prev => prev + 1)
    },
    [checkAdjacency, setNumTurns, shuffledBoard]
  )

  // Shuffle animation
  useEffect(() => {
    const emptyTile = shuffledBoard.current.get(numPuzzlePieces)
    if (!puzzleContainerRef.current || !emptyTile) {
      return
    }
    const tiles = puzzleContainerRef.current.children

    setTimeout(() => {
      for (let i = 0; i < tiles.length - 1; i++) {
        const boardTile = shuffledBoard.current.get(i)

        if (!boardTile) {
          continue
        }
        const tile = tiles[i] as HTMLButtonElement

        tile.style.transform = translationMatrix[boardTile.currentIndex]
      }

      setIsShuffled(true)
    }, 500)
  }, [shuffledBoard])

  // Check for completion state
  useEffect(() => {
    if (!puzzleContainerRef.current || numTurns === 0) {
      return
    }

    // check the current state of the board against the initial state of the board (ie the piece's currentIndex vs the key in the puzzle map)
    const allCorrect = M.allPass(
      (piece, correctIndex) => piece.currentIndex === correctIndex,
      shuffledBoard.current
    )

    if (!allCorrect) {
      return
    }

    Analytics.track('404-fem-puzzle-completed', {
      numberOfTurns: numTurns,
    })

    setTimeout(() => {
      setIsComplete(true)
    }, 750)
  }, [numTurns, shuffledBoard])

  // Check for board state for adjacency to enable/disable buttons
  // Disable all buttons except for those adjacent to the empty tile
  useEffect(() => {
    if (!puzzleContainerRef.current) {
      return
    }
    const tiles = puzzleContainerRef.current.children

    for (let i = 0; i < shuffledBoard.current.size; i++) {
      const tileElement = tiles[i] as HTMLButtonElement
      if (!tileElement) {
        return
      }
      const isAdjacent = checkAdjacency(i)
      tileElement.disabled = !isAdjacent
    }
  }, [checkAdjacency, numTurns])

  useEffect(() => {
    async function animateSolution() {
      if (!solution) return
      setAutoSolved(true)

      for (const move of solution) {
        const buttonEl = puzzleContainerRef.current?.children[
          parseInt(move)
        ] as HTMLButtonElement
        swapTiles(parseInt(move), buttonEl)
        // add delay for sliding animation
        await new Promise(resolve => setTimeout(resolve, 250))
      }
    }

    void animateSolution()
  }, [solution, swapTiles])

  return (
    <div className={styles.sliderPuzzleWrapper}>
      <div
        className={classnames(styles.puzzleWrapper, isComplete && styles.complete)}
      >
        <div className={styles.fullPuzzleContainer}>
          <FullLogo className={styles.fullPuzzle} />
        </div>
        <div
          className={styles.puzzleContainer}
          ref={puzzleContainerRef}
          id="puzzle-container"
          onTransitionEnd={e => {
            const target = e.target as HTMLDivElement
            if (target.id === 'puzzle-container' && puzzleContainerRef.current) {
              puzzleContainerRef.current.classList.add(styles.hidden)
            }
          }}
          data-shuffled={isShuffled}
        >
          {M.mapEntries((piece, index) => {
            if (piece.src === 'empty') {
              return (
                <div
                  key={piece.src}
                  className={styles.emptyTile}
                  style={{
                    transform:
                      translationMatrix[
                        shuffledBoard.current?.get(emptyPieceIndex)?.currentIndex ??
                          8
                      ],
                  }}
                />
              )
            }
            return (
              <button
                key={piece.src}
                style={{
                  transform: translationMatrix[index],
                }}
                onClick={e => {
                  const buttonEl = e.target as HTMLButtonElement
                  swapTiles(index, buttonEl)
                }}
              >
                <Image src={piece.src} alt="Piece of the Mercury Logo" fill />
              </button>
            )
          }, initialBoard)}
        </div>
      </div>
      {numTurns > 0 && (
        <>
          <span>Turns: {numTurns}</span>
          <MWDSUnstyledButton
            analyticsEvent="404-fem-puzzle-solve"
            className={classnames(
              styles.solveButton,
              buttonStyles.button,
              buttonStyles.light,
              buttonStyles.primary
            )}
            onClick={async () => {
              await solvePuzzle()
            }}
            aria-hidden={isComplete}
            aria-disabled={isLoading || autoSolved}
          >
            {isLoading && !autoSolved ? <MWDSLoadingDots /> : 'Solve'}
          </MWDSUnstyledButton>
          {error && <span className={styles.error}>{error.message}</span>}
        </>
      )}
    </div>
  )
}

export default SliderPuzzle
