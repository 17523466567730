export type PuzzleState = Record<string, number>

type PieceInfo = {
  src: string
  currentIndex: number
}

export type PuzzleMap = Map<number, PieceInfo>

export const depth = 3
export const numPuzzlePieces = depth ** 2 - 1 // 8
// The empty piece will always be the last index
export const emptyPieceIndex = numPuzzlePieces

export function convertPuzzleMapToRecord(puzzleMap: PuzzleMap) {
  const record: PuzzleState = {}
  for (const [key, value] of puzzleMap) {
    if (key === emptyPieceIndex) {
      record.empty = value.currentIndex
      continue
    }
    record[key] = value.currentIndex
  }
  return record
}

// Heuristic function (number of misplaced tiles)
export function countMisplacedTiles(
  state: PuzzleState,
  goalState: PuzzleState
): number {
  let misplacedTiles = 0
  for (const key in state) {
    if (state[key] !== goalState[key]) {
      misplacedTiles++
    }
  }
  return misplacedTiles
}

// Helper function to compare two puzzle states
export function compareStates(state1: PuzzleState, state2: PuzzleState): boolean {
  for (const key in state1) {
    if (state1[key] !== state2[key]) {
      return false
    }
  }
  return true
}

// Helper function to serialize a puzzle state to a string for tracking visited states
export function serializeState(state: PuzzleState): string {
  let serializableState = ''
  for (let i = 0; i <= numPuzzlePieces; i++) {
    // Assuming numPuzzlePieces does not include the empty piece
    const piece = state[i]
    serializableState += `${piece}`
  }
  return serializableState
}
