import classnames from 'classnames'
import React from 'react'
import styles from './styles.module.css'

type MWDSLoadingDotsProps = {
  classNames?: {
    /**
     * CSS class to apply to the parent div of the SVG.
     */
    wrapper?: string
  }

  /**
   * When true, updates the colors to be visible on a dark background.
   *
   * @default false
   */
  onDarkBackground?: boolean

  /**
   * When true, sets the `data-suspense` attribute to be `true` when the Loading component is called as a suspense fallback.
   *
   * This is so that the legitimate Loading component rendered after successful login doesn't falsely trigger during functional lazyLoading tests.
   * https://17.reactjs.org/docs/concurrent-mode-suspense.html
   *
   * @default false
   */
  isFallback?: boolean
}

/**
 * This component renders 3 animated dots that indicate a loading state.
 */
const MWDSLoadingDots = ({
  classNames,
  isFallback,
  onDarkBackground = false,
}: MWDSLoadingDotsProps) => (
  <div
    className={classnames(
      styles.loadingStyleDefault,
      onDarkBackground && styles.loadingStyleDarkBackground,
      styles.loadingWrapper,
      classNames?.wrapper
    )}
    data-suspense={isFallback}
  >
    <span className={styles.loadingText}>Loading...</span>

    <svg
      className={styles.loadingDots}
      viewBox="0 0 60 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="16" r="4" />
      <circle cx="30" cy="16" r="4" />
      <circle cx="53" cy="16" r="4" />
    </svg>
  </div>
)

export default MWDSLoadingDots
